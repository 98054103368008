<div class="d-flex bg-warning align-items-center pl-4 pt-4">
    <h1 class="title ">{{'sidebar.user' | translate}}</h1>
    <!--<span class="subTitle text-primary ml-2">Usuarios</span>-->
</div>

<div class="bg-gray w-100 pt-3 px-3 px-sm-5">
    <div class="row">
        <div class="col-sm-6 col-lg-4">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required [(ngModel)]="modalFilter.keyword"/>
                <label for="name">{{'general.nome' | translate}} - E-mail</label>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4">
            <div class="input-material">
                <select id="status" class="form-control" name="type" [(ngModel)]="modalFilter.profile">
                    <option selected [value]="''">Todos</option>
                    <option [value]="'administrator'">{{getProfileTranslate('administrator')}}</option>
                    <option [value]="'commercial'">{{getProfileTranslate('commercial')}}</option>
                    <option [value]="'ar_local'">{{getProfileTranslate('ar_local')}}</option>
                    <option [value]="'ar_inter'">{{getProfileTranslate('ar_inter')}}</option>
                    <option [value]="'arts'">{{getProfileTranslate('arts')}}</option>
                    <option [value]="'register'">{{getProfileTranslate('register')}}</option>
                    <option [value]="'consult'">{{getProfileTranslate('consult')}}</option>
                    <option [value]="'new_business'">{{'novosNegocios' | translate}}</option>
                    <option [value]="'gamal'">GAMAL</option>
                    <option [value]="'tributary'">Planejamento Tributário</option>
                    <option [value]="'commercial_genfar'">Genfar {{getProfileTranslate('commercial')}}</option>
                    <option [value]="'ar_local_genfar'">Genfar {{getProfileTranslate('ar_local')}}</option>
                    <option [value]="'register_genfar'">Genfar {{getProfileTranslate('register')}}</option>
                    <option [value]="'arts_genfar'">Genfar Master Data</option>
                    <option [value]="'product_commercial'">{{'produtoComercial' | translate}}</option>
                    <option [value]="'product_ar_local'">{{'produtoArLocal' | translate}}</option>
                    <option [value]="'product_exported'">Comercial Terceiros</option>
                    <option [value]="'product_gpl'">{{'produtoGLP' | translate}}</option>
                </select>
                <label for="status" class="mb-0 mr-3">Perfil</label>
            </div>
        </div>
        <div class="col-sm-6 col-lg-4">
            <div class="input-material">
                <select class="form-control" [(ngModel)]="modalFilter.country">
                    <option [value]="''">Todos</option>
                    <option *ngFor="let e of modelListCountry" [value]="e.name">{{e.description}}</option>
                </select>
                <label class="mb-0 mr-3">País</label>
            </div>
        </div>
        <div class="col-12 mb-4 pl-3 pl-md-4">
            <div class="text-right">
                <a href="javascript:void (0)" type="submit" class="btn btn-outline-primary mr-1"
                   (click)="actionList()">
                    {{'general.pesquisar' | translate}}
                </a>
                <a href="javascript:$('#modalGroup').modal('show');void('Editar');" (click)="actionNew()"
                   class="btn text-warning btn-primary mr-1"
                   type="button"> {{'general.novo' | translate}}</a>
            </div>
        </div>
    </div>


    <div class="modal-custom modal fade" id="modalGroup" data-keyboard="false" data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="title"
                        *ngIf="isNullOrUndefined(modelUser.id)">{{'general.novosusuarios' | translate}}</h2>
                    <h2 class="title"
                        *ngIf="!isNullOrUndefined(modelUser.id)">{{'general.editarusuarios' | translate}}</h2>
                    <a href="javascript:void('Fechar');" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">X</span>
                    </a>
                </div>
                <div class="modal-body modal-body--small modal_resource-body">

                    <fieldset class="row custom__form" autocomplete="off">
                        <div class="col-xs-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="input-material custom__input modal_text_input">
                                        <input class="form-control" name="name" type="text" required
                                               [(ngModel)]="modelUser.name"/>
                                        <label class="disabled">{{'general.nome' | translate}}</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="input-material custom__input modal_text_input">
                                        <input class="form-control" name="name" type="text" required
                                               [(ngModel)]="modelUser.email"/>
                                        <label class="disabled">E-mail</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="input-material custom__input modal_text_input">
                                        <select id="profileUpdate" class="form-control" name="type"
                                                [(ngModel)]="modelUser.profile">
                                            <option value="undefined" selected disabled>Selecione</option>
                                            <option [value]="'administrator'">{{getProfileTranslate('administrator')}}</option>
                                            <option [value]="'commercial'">{{getProfileTranslate('commercial')}}</option>
                                            <option [value]="'ar_local'">{{getProfileTranslate('ar_local')}}</option>
                                            <option [value]="'ar_inter'">{{getProfileTranslate('ar_inter')}}</option>
                                            <option [value]="'arts'">{{getProfileTranslate('arts')}}</option>
                                            <option [value]="'register'">{{getProfileTranslate('register')}}</option>
                                            <option [value]="'consult'">{{getProfileTranslate('consult')}}</option>
                                            <option [value]="'new_business'">{{'novosNegocios' | translate}}</option>
                                            <option [value]="'gamal'">GAMAL</option>
                                            <option [value]="'tributary'">Planejamento Tributário</option>
                                            <option [value]="'commercial_genfar'">Genfar {{getProfileTranslate('commercial')}}</option>
                                            <option [value]="'ar_local_genfar'">Genfar {{getProfileTranslate('ar_local')}}</option>
                                            <option [value]="'register_genfar'">Genfar {{getProfileTranslate('register')}}</option>
                                            <option [value]="'arts_genfar'">Genfar Master Data</option>
                                            <option [value]="'product_commercial'">{{'produtoComercial' | translate}}</option>
                                            <option [value]="'product_ar_local'">{{'produtoArLocal' | translate}}</option>
                                            <option [value]="'product_exported'">Comercial Terceiros</option>
                                            <option [value]="'product_gpl'">{{'produtoGLP' | translate}}</option>
                                        </select>
                                        <label for="profileUpdate" class="mb-0 mr-3">Perfil</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="input-material custom__input modal_text_input">
                                        <select class="form-control" [(ngModel)]="modelRequestContinent"
                                                (change)="onChangeContinent($event)">
                                            <option value="undefined" selected disabled>Selecione</option>
                                            <option *ngFor="let e of listContinents"
                                                    [value]="e.id">{{e.name}}</option>
                                        </select>
                                        <label class="mb-0 mr-3">Continente</label>
                                    </div>
                                </div>

                                <div class="col-12 col-md-12">
                                    <div class="input-material custom__input modal_text_input">
                                        <select class="form-control" [(ngModel)]="modelUser.country">
                                            <option value="undefined" selected disabled>Selecione</option>
                                            <option *ngFor="let e of modelListCountry"
                                                    [value]="e.value">{{e.name}}</option>
                                        </select>
                                        <label class="mb-0 mr-3">{{'general.pais' | translate}}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </fieldset>
                </div>

                <div class="row" style="margin-top:20px;">
                    <div class="col-6 col-md-6">
                        <a class="btn btn-block btn-danger btn-cancel" href="javascript:void('cancel');"
                           data-dismiss="modal" aria-label="Close">{{'general.cancelar' | translate}}</a>
                    </div>
                    <div class="col-6 col-md-6">
                        <a class="btn btn-block btn-quaternary " href="javascript:void('save');"
                           (click)="actionSave()">{{'general.salvar' | translate}}</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class=" w-100 pt-3 px-3 px-sm-5">
    <div class="container">
        <div class="row">
            <div class="table-responsive">
                <table class="table table-striped table-eurouser">
                    <thead>
                    <tr>
                        <th scope="col">{{'general.nome' | translate}}</th>
                        <th scope="col">E-mail</th>
                        <th class="text-center" scope="col">Perfil</th>
                        <th class="text-center" scope="col">{{'general.pais' | translate}}</th>
                        <th scope="col" class="w-10">{{'general.editar/excluir' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let list of modalResult.results |
            paginate: {
             itemsPerPage: modalResult.pageSize,
             currentPage: modalResult.currentPage,
             totalItems: modalResult.totalCount}">
                        <td>{{list.name}}</td>
                        <td>{{list.email}}</td>
                        <td class="text-center">{{getProfileTranslate(list.profile)}}</td>
                        <td class="text-center">{{list.country}}</td>

                        <td>
                            <a href="javascript:void('Editar');"
                               class="btn btn-xs btn-quaternary px-1 py-0 m-1"
                               (click)="detailUser(list)"
                               style="max-width: 36px;height: 36px;width: 100%">
                                <svg-icon class="svg-secondary" src="assets/svg/edit.svg"></svg-icon>
                            </a>
                            <a href="javascript:void('Excluir');" class="btn btn-xs btn-danger px-1 py-0 m-1"
                               (click)="actionDelete(list.id)"
                               style="max-width: 36px;height: 36px;width: 100%">
                                <svg-icon class="svg-tertiary svg-white transform-scale--9"
                                          src="assets/svg/trash.svg"></svg-icon>
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="text-center my-3">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>

</div>

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FaqComponent} from './views/help/faq/faq.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {MyListComponent} from './views/my-list/my-list.component';
import {NotificationComponent} from './views/notification/notification.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {EventBusService} from './service/event-bus.service';
import { CountryFlagsComponent } from './views/components/country-flags/country-flags.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OnlyLettersDirective } from './directive/only-letters.directive';
import {
    RegisterCountryManufactureModule
} from "./views/register/register-country-manufacture/register-country-manufacture.module";
import {RegisterNewBusinessModule} from "./views/register/register-new-business/register-new-business.module";
import {RegisterGenfarModule} from "./views/register/register-genfar/register-genfar.module";
import { ImagePreloaderDirective } from './directive/image-preloader.directive';
import {RegisterNewProductsBrModule} from "./views/register/register-new-products-br/register-new-products-br.module";
import {
    RegisterFinishedProductsModule
} from "./views/register/register-finished-products/register-finished-products.module";
import {RegisterBulkModule} from "./views/register/register-bulk/register-bulk.module";
import {
    RegisterFinishedProductsPartnersModule
} from "./views/register/register-finished-products-partners/register-finished-products-partners.module";


registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        FaqComponent,
        LogoutComponent,
        RegisterComponent,
        HomeComponent,
        AutocompleteComponent,
        MyListComponent,
        NotificationComponent,
        RegisterDetailComponent,
        UserComponent,
        AuthenticateComponent,
        OnlyNumberDirective,
        OnlyNumberDirective,
        CountryFlagsComponent,
        OnlyLettersDirective,
        ImagePreloaderDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxPaginationModule,
        RegisterCountryManufactureModule,
        RegisterNewBusinessModule,
        RegisterGenfarModule,
        RegisterNewProductsBrModule,
        RegisterFinishedProductsModule,
        RegisterBulkModule,
        RegisterFinishedProductsPartnersModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        TranslateService,
        AppDataService,
        EventBusService,
    ],
    exports: [
        CountryFlagsComponent,
        OnlyNumberDirective
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

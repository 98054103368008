import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

export class BaseComponent {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    getBaseURL() {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
            this.setCulture('pt-BR');
        } else if (language === 'en') {
            language = 'en';
            this.setCulture('en-US');
        } else if (language === 'es') {
            language = 'es';
            this.setCulture('es-ES');
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    destroyModal(idModal) {
        const elModalRemove = $('#' + idModal);
        if (elModalRemove[0]) {
            elModalRemove.remove();
        }
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: localStorage.getItem('culture') === 'pt-BR' ? 'Sim': 'Sí',
            cancelButtonText: localStorage.getItem('culture') === 'pt-BR' ? 'Não': 'No',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        if (this.isNullOrUndefined(value)) { return; }
        return value + '.svg';
        /*switch (value) {
            case 'chile':
                return 'chile.svg';
            case 'panama':
                return 'panama.svg';
            case 'peru':
                return 'peru.svg';
            case 'bolivia':
                return 'bolivia.svg';
            case 'venezuela':
                return 'venezuela.svg';
            case 'argentina':
                return 'argentina.svg';
            case 'mexico':
                return 'mexico.svg';
            case 'equador':
                return 'equador.svg';
            case 'guatemala':
                return 'guatemala.svg';
            case 'brazil':
                return 'brazil.svg';
            case 'colombia':
                return 'colombia.svg';
            case 'uruguay':
            case 'uruguai':
                return 'uruguai.svg';
            case 'paraguai':
            case 'paraguay':
                return 'paraguay.svg';
        }*/
    }

    getCountryNameByKey(s: string) {
        if (this.isNullOrUndefined(s)) { return; }
        let translation: string;
        translation = this.translate.instant('country.' + s);
        // console.log('translation');
        // console.log(translation);
        if (translation === 'country.' + s) {
            return this.formatString(s);
        }
        return translation;
       //  return this.translate.instant('country.' + value);
    }

    formatString(str: string): string {
        if (this.isNullOrUndefined(str)) { return; }
        let formattedStr = str.replace(/-/g, ' ');
        formattedStr = formattedStr.replace(/\b\w/g, char => char.toUpperCase());
        return formattedStr;
    }

    handlerStepBar(value, flowType?) {
        if (flowType === 'finished-products-partners') {
            switch (value) {
                case 1:
                    return 'width: 5%';
                case 2:
                    return 'width: 100%';
            }
        } else if (flowType === 'new-business') {
            switch (value) {
                case 1:
                    return 'width: 0%';
                case 2:
                    return 'width: 23%';
                case 3:
                    return 'width: 41%';
                case 4:
                    return 'width: 61%';
                case 5:
                    return 'width: 78%';
                case 6:
                    return 'width: 100%';
            }
        } else {
            switch (value) {
                case 1:
                    return 'width: 0%';
                case 2:
                    return 'width: 25%';
                case 3:
                    return 'width: 50%';
                case 4:
                    return 'width: 75%';
                case 5:
                    return 'width: 100%';
            }
        }
    }

    handlerOnlyNumber(str: string) {
        return str.match(/\d+/g).join('');
    }

    getTranslateInstantKey(s: string) {
        return this.translate.instant(s);
    }

    objLocaleDateRangePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar',
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }

    getIconByStatus(status: string) {
        let str = '';
        switch (status) {
            case 'approve':
                str = 'check-mark';
                break;
            case 'disapprove':
                str = 'x-mark';
                break;
            default:
                str = 'time';
                break;
        }
        return str;
    }

    getProfileTranslate(s: string) {
        let str = '';
        switch (s) {
            case 'administrator':
                str = this.translate.currentLang === 'pt' ? 'Admin' : 'Admin';
                break;
            case 'commercial':
                str = this.translate.currentLang === 'pt' ? 'Comercial' : 'Comercial';
                break;
            case 'ar_local':
                str = this.translate.currentLang === 'pt' ? 'AR Local' : 'AR Local';
                break;
            case 'ar_inter':
                str = this.translate.currentLang === 'pt' ? 'AR Internacional' : 'AR Internacional';
                break;
            case 'arts':
                str = this.translate.currentLang === 'pt' ? 'Artes' : 'Artes';
                break;
            case 'register':
                str = this.translate.currentLang === 'pt' ? 'Cadastro' : 'Cadastro';
                break;
            case 'consult':
                str = this.translate.currentLang === 'pt' ? 'Consulta' : 'Consulta';
                break;
            case 'new_business':
                str = this.translate.currentLang === 'pt' ? 'Novos Negócios' : 'Novos Negócios';
                break;
            case 'gamal':
                str = this.translate.currentLang === 'pt' ? 'GAMAL' : 'GAMAL';
                break;
            case 'tributary':
                str = this.translate.currentLang === 'pt' ? 'Tributário' : 'Tributário';
                break;
            default:
                str = '';
                break;
        }
        return str;
    }

    getCountryShort(s: string) {
        let str = '';
        switch (s) {
            case 'chile':
                str= 'CH';
                break;
            case 'belize':
                str= 'BLZ';
                break;
            case 'honduras':
                str= 'HND';
                break;
            case 'costa-rica':
                str= 'CRI';
                break;
            case 'nicaragua':
                str= 'NIC';
                break;
            case 'panama':
                str= 'PAN';
                break;
            case 'peru':
                str= 'PER';
                break;
            case 'bolivia':
                str= 'BOL';
                break;
            case 'venezuela':
                str= 'VEN';
                break;
            case 'argentina':
                str= 'ARG';
                break;
            case 'mexico':
                str= 'MEX';
                break;
            case 'equador':
                str= 'ECU';
                break;
            case 'guatemala':
                str= 'GUA';
                break;
            case 'brazil':
                str= 'BRA';
                break;
            case 'republica-dominicana':
                str= 'DOM';
                break;
            case 'colombia':
                str= 'COL';
                break;
            case 'el-salvador':
                str= 'SLV';
                break;
            case 'uruguay':
            case 'uruguai':
                str= 'URU';
                break;
            case 'paraguai':
            case 'paraguay':
                str= 'PAR';
                break;
        }
        return str;
    }

    getNameStepByNumber(x: number, str?: string) {
        switch (x) {
            case 1:
                return str ? str : this.translate.instant('novosNegocios');
                break;
            case 2:
                return this.translate.instant('arBrasil');
                break;
            case 3:
                return this.translate.instant('artes');
                break;
            case 4:
                return this.translate.instant('gamal');
                break;
            case 5:
                return this.translate.instant('tributario');
                break;
            case 6:
                return 'Cadastro';
                break;
        }
        /*switch (x) {
            case 1:
                return this.translate.instant('novocadastro.etapa1.title');
                break;
            case 2:
                return this.translate.instant('novocadastro.etapa2.title');
                break;
            case 3:
                return this.translate.instant('novocadastro.etapa3.title');
                break;
            case 4:
                return this.translate.instant('novocadastro.etapa4.title');
                break;
            case 5:
                return 'Cadastro';
                break;
        }*/
    }
    convertToNumber(s: string){
        const x = parseInt(s, 0) + 1;
        return this.getNameStepByNumber(x);
    }

    getNameRequestByType(str: string) {
        switch (str) {
            case 'genfar':
                return 'GENFAR';
                break;
            case 'new-business':
                return this.translate.instant('novosNegocios');
                break;
            case 'pmoi':
                return this.translate.instant('requestPmoi');
                break;
            case 'pmoint':
                return this.translate.instant('requestPmoint');
                break;
            case 'new-products-br':
                return this.translate.instant('novosProdutosBr');
                break;
            case 'finished-products-partners':
                return this.translate.instant('produtoProdTerceiros');
                break;
            case 'bulk':
                return this.translate.instant('bulk');
                break;
            case 'finished-products':
                return this.translate.instant('produtoVindoTerceiro');
                break;
            default:
                return ' -- ';
                break;
        }
    }

    getLinkRequestByType(str: string) {
        switch (str) {
            case 'genfar':
                return '/register-genfar/';
                break;
            case 'new-business':
                return '/register-new-business/';
                break;
            case 'pmoi':
                return '/register/';
                break;
            case 'pmoint':
                return '/register-country-manufacture/';
                break;
            case 'new-products-br':
                return '/register-new-products-br/';
                break;
            case 'finished-products':
                return '/register-finished-products/';
                break;
            case 'finished-products-partners':
                return '/register-finished-products-to-partners/';
                break;
            case 'bulk':
                return '/register-bulk/';
                break;
            default:
                return '/register/';
                break;
        }
    }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {FaqComponent} from './views/help/faq/faq.component';
import {LogoutComponent} from './views/logout/logout.component';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {MyListComponent} from './views/my-list/my-list.component';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {AuthGuard} from './service/auth-guard.service';
import {AuthGuardUser} from './service/auth-guard-user.service';

const routes: Routes = [
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {
        path: '', component: MainComponent,
        children: [
            {path: '', component: HomeComponent},
            {path: 'faq', component: FaqComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'register/:id', component: RegisterComponent},
            {path: 'register-detail', component: RegisterDetailComponent},
            {
                path: 'register-country-manufacture',
                loadChildren: () => import('./views/register/register-country-manufacture/register-country-manufacture-routing.module').then(m => m.RegisterCountryManufactureRoutingModule)
            },
            {
                path: 'register-new-business',
                loadChildren: () => import('./views/register/register-new-business/register-new-business-routing.module').then(m => m.RegisterNewBusinessRoutingModule)
            },
            {
                path: 'register-genfar',
                loadChildren: () => import('./views/register/register-genfar/register-genfar-routing.module').then(m => m.RegisterGenfarRoutingModule)
            },
            {
                path: 'register-new-products-br',
                loadChildren: () => import('./views/register/register-new-products-br/register-new-products-br-routing.module').then(m => m.RegisterNewProductsBrRoutingModule)
            },
            {
                path: 'register-finished-products',
                loadChildren: () => import('./views/register/register-finished-products/register-finished-products-routing.module').then(m => m.RegisterFinishedProductsRoutingModule)
            },
            {
                path: 'register-bulk',
                loadChildren: () => import('./views/register/register-bulk/register-bulk-routing.module').then(m => m.RegisterBulkRoutingModule)
            },
            {
                path: 'register-finished-products-to-partners',
                loadChildren: () => import('./views/register/register-finished-products-partners/register-finished-products-partners-routing.module').then(m => m.RegisterFinishedProductsPartnersRoutingModule)
            },
            {path: 'home', component: HomeComponent},
            {path: 'my-list', component: MyListComponent},
            {path: 'user', component: UserComponent, canActivate: [AuthGuardUser]},
        ],
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { RegisterFinishedProductsRoutingModule } from './register-finished-products-routing.module';
import { RegisterFinishedProductsComponent } from './register-finished-products.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../app.module';
import {HttpClient} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {AppDataService} from '../../../service/app_data.service';
import {EventBusService} from '../../../service/event-bus.service';

@NgModule({
  declarations: [RegisterFinishedProductsComponent],
  imports: [
    CommonModule,
    RegisterFinishedProductsRoutingModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    AngularSvgIconModule.forRoot(),
  ],
  providers: [
    TranslateService,
    AppDataService,
    EventBusService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegisterFinishedProductsModule { }

import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RegisterNewProductsBrRoutingModule} from './register-new-products-br-routing.module';
import {RegisterNewProductsBrComponent} from './register-new-products-br.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HttpLoaderFactory} from '../../../app.module';
import {HttpClient} from '@angular/common/http';
import {AppDataService} from '../../../service/app_data.service';
import {EventBusService} from '../../../service/event-bus.service';


@NgModule({
  declarations: [RegisterNewProductsBrComponent],
    imports: [
        CommonModule,
        RegisterNewProductsBrRoutingModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        AngularSvgIconModule.forRoot(),
    ],
    providers: [
        TranslateService,
        AppDataService,
        EventBusService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RegisterNewProductsBrModule { }

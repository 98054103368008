import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterFinishedProductsComponent} from './register-finished-products.component';

const routes: Routes = [
  {path: '', component: RegisterFinishedProductsComponent},
  {path: ':id', component: RegisterFinishedProductsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterFinishedProductsRoutingModule { }

import {AfterViewInit, Component, ViewChild, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    ApexNonAxisChartSeries,
    ApexAxisChartSeries,
    ApexPlotOptions,
    ApexChart,
    ApexStroke,
    ApexMarkers,
    ApexYAxis,
    ApexXAxis,
    ApexGrid,
    ApexLegend,
    ApexResponsive,
    ApexTitleSubtitle,
    ApexDataLabels,
    ApexFill,
    ApexTooltip,
    ChartComponent,
} from 'ng-apexcharts';
import {Dashboard} from '../../model/dashboard/dashboard';
import {DashboardService} from '../../service/dashboard.service';
import {UserService} from '../../service/user.service';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    labels: string[];
    colors: string[];
    legend: ApexLegend;
    plotOptions: ApexPlotOptions;
    responsive: ApexResponsive | ApexResponsive[];
};

export type ChartOptionsSecondary = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xAxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    markers: ApexMarkers;
    tooltip: any; // ApexTooltip;
    yAxis: ApexYAxis;
    grid: ApexGrid;
    legend: ApexLegend;
    title: ApexTitleSubtitle;
    fill: ApexFill;
};

export type ChartOptionsTertiary = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xAxis: ApexXAxis;
    yAxis: ApexYAxis;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    fill: ApexFill;
    tooltip: ApexTooltip;
    stroke: ApexStroke;
    legend: ApexLegend;
};

declare global {
    interface Window {
        Apex: any;
    }
}
declare var $: any;

window.Apex = {
    chart: {
        toolbar: {
            show: false
        }
    },
    tooltip: {
        shared: false
    },
    legend: {
        show: false
    }
};

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild('chart') chart: ChartComponent;

    categoriesCustom = [];
    categoriesCustomQuaternary = [];
    categoriesCustomQuinary = [];
    modelDashboard: Dashboard = new Dashboard();

    chartOptions: any = {
        labels: [],
        series: [],
        chart: {
            type: 'radialBar',
            id: 'primaryChart',
            height: '320'
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                    image: undefined
                },
                dataLabels: {
                    name: {
                        show: true
                    },
                    value: {
                        show: true,
                        formatter: function (val) {
                            return val + ' ';
                        }
                    }
                }
            }
        },
        colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
        legend: {
            show: true,
            floating: true,
            fontSize: '15px',
            position: 'left',
            offsetX: 0,
            offsetY: 10,
            labels: {
                useSeriesColors: true
            },
            formatter(seriesName, opts) {
                return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
            },
            itemMargin: {
                horizontal: 5
            }
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }
        ]
    };

    chartOptionsTertiary: any = {
        series: [],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true
        },
        chart: {
            type: 'bar',
            height: 350,
            id: 'tertiaryChart'
        },
        xAxis: {
            categories: this.categoriesCustom,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        fill: {
            opacity: 1,
            colors: ['#1b2e5b', '#fae100']
        },
        tooltip: {
            y: {
                formatter(val) {
                    return '' + val + ' ';
                }
            }
        }
    };

    chartOptionsSecondary: any = {
        series: this.makeDataSecondary(),
        chart: {
            height: 350,
            type: 'line',
            id: 'secondaryChart'
        },
        stroke: {
            width: 5,
            curve: 'straight',
            dashArray: this.makeDataSecondaryDataArrays(),
        },
        legend: {
            tooltipHoverFormatter(val, opts) {
                return (
                    val +
                    ' - <strong>' +
                    opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                    '</strong>'
                );
            }
        },
        markers: {
            colors: ['#1b2e5b', '#fae100', '#656f88', '#00358e'],
            size: 0,
            hover: {
                sizeOffset: 6
            },
        },
        xaxis: {
            categories: this.makeDataSecondaryCategories()
        },
        tooltip: {
            y: [
                {
                    title: {
                        formatter(val) {
                            return val + ' (mins)';
                        },
                        colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
                    }
                },
                {
                    title: {
                        formatter(val) {
                            return val + ' per session';
                        },
                        colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],

                    }
                },
                {
                    title: {
                        formatter(val) {
                            return val;
                        },
                        colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
                    }
                }
            ]
        },
        grid: {
            borderColor: '#f1f1f1'
        }
    };

    chartOptionsQuaternary: any = {
        series: [],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true
        },
        chart: {
            type: 'bar',
            height: 320,
            id: 'quaternaryChart'
        },
        xAxis: {
            categories: this.categoriesCustomQuaternary,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        fill: {
            opacity: 1,
            colors: ['#1b2e5b', '#fae100']
        },
        tooltip: {
            y: {
                formatter(val) {
                    return '' + val + ' ';
                }
            }
        }
    };

    chartOptionsQuinary: any = {
        series: [],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true
        },
        chart: {
            type: 'bar',
            height: 350,
            id: 'quinaryChart'
        },
        xAxis: {
            categories: this.categoriesCustomQuinary,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%'
            }
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        fill: {
            opacity: 1,
            colors: ['#fae100']
        },
        tooltip: {
            y: {
                formatter(val) {
                    return '' + val + ' ';
                }
            }
        }
    };

    flagResultMoc = true;

    btnNew = false;

    currentFlowType = null;
    tabFlowType = [
        {text: 'PMO Inovação', value: 'pmoi'},
        {text: 'PMO Corp. Operações Inter.', value: 'pmoint'},
        {text: 'Novos Negócios', value: 'new-business'},
        {text: 'Genfar', value: 'genfar'},
        {text: this.translate.instant('novosProdutosBr'), value: 'new-products-br'},
        {text: this.translate.instant('produtoVindoTerceiro'), value: 'finished-products'},
        {text: 'Bulk', value: 'bulk'},
        {text: this.translate.instant('produtoParaTerceiro'), value: 'finished-products-partners'},
    ];

    stepsArray: any = [1, 2, 3, 4, 5];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public dashboardService: DashboardService,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }


    ngOnInit() {
        this.currentFlowType = this.tabFlowType[0];
    }

    ngAfterViewInit() {

        this.translate.get('headerSalutation').subscribe((text: string) => {
            this.tabFlowType = [
                {text: 'PMO Inovação', value: 'pmoi'},
                {text: 'PMO Corp. Operações Inter.', value: 'pmoint'},
                {text: 'Novos Negócios', value: 'new-business'},
                {text: 'Genfar', value: 'genfar'},
                {text: this.translate.instant('novosProdutosBr'), value: 'new-products-br'},
                {text: this.translate.instant('produtoVindoTerceiro'), value: 'finished-products'},
                {text: this.translate.instant('produtoParaTerceiro'), value: 'finished-products-partners'},
                {text: 'Bulk', value: 'bulk'},
            ];
        });


        this.actionDashBoard();
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.btnNew = data.profile === 'commercial' || data.profile === 'administrator';
                }
            }
        });

        $(() => {
            $('[data-toggle="popover"]').popover({
                trigger: 'focus'
            });
        });
    }

    actionDashBoard() {
        this.loading.show();
        this.dashboardService.get(this.currentFlowType?.value).subscribe({
            next: data => {
                this.modelDashboard = data as Dashboard;
                console.log(this.modelDashboard.latest);
                this.makeData();
                this.makeDataTertiary();
                this.makeDataSecondary();
                this.makeDataQuaternary();
                this.makeDataQuinary();
                this.updateQuarterChartCategories('secondaryChart', this.makeDataSecondaryCategories());
                this.loading.hide();
            }, error: err => super.onError(err)
        });
    }

    resetDataCharts() {
        this.categoriesCustom = [];
        this.categoriesCustomQuaternary = [];
        this.categoriesCustomQuinary = [];
        this.modelDashboard = new Dashboard();
        this.chartOptions = {
            labels: [],
            series: [],
            chart: {
                type: 'radialBar',
                id: 'primaryChart',
                height: '320'
            },
            plotOptions: {
                radialBar: {
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined
                    },
                    dataLabels: {
                        name: {
                            show: true
                        },
                        value: {
                            show: true,
                            formatter: function (val) {
                                return val + ' ';
                            }
                        }
                    }
                }
            },
            colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
            legend: {
                show: true,
                floating: true,
                fontSize: '15px',
                position: 'left',
                offsetX: 0,
                offsetY: 10,
                labels: {
                    useSeriesColors: true
                },
                formatter(seriesName, opts) {
                    return seriesName + ':  ' + opts.w.globals.series[opts.seriesIndex];
                },
                itemMargin: {
                    horizontal: 5
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            show: false
                        }
                    }
                }
            ]
        };

        this.chartOptionsTertiary = {
            series: [],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true
            },
            chart: {
                type: 'bar',
                height: 350,
                id: 'tertiaryChart'
            },
            xAxis: {
                categories: this.categoriesCustom,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1,
                colors: ['#1b2e5b', '#fae100']
            },
            tooltip: {
                y: {
                    formatter(val) {
                        return '' + val + ' ';
                    }
                }
            }
        };

        this.chartOptionsSecondary = {
            series: this.makeDataSecondary(),
            chart: {
                height: 350,
                type: 'line',
                id: 'secondaryChart'
            },
            stroke: {
                width: 5,
                curve: 'straight',
                dashArray: this.makeDataSecondaryDataArrays(),
            },
            legend: {
                tooltipHoverFormatter(val, opts) {
                    return (
                        val +
                        ' - <strong>' +
                        opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
                        '</strong>'
                    );
                }
            },
            markers: {
                colors: ['#1b2e5b', '#fae100', '#656f88', '#00358e'],
                size: 0,
                hover: {
                    sizeOffset: 6
                },
            },
            xaxis: {
                categories: this.makeDataSecondaryCategories()
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter(val) {
                                return val + ' (mins)';
                            },
                            colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
                        }
                    },
                    {
                        title: {
                            formatter(val) {
                                return val + ' per session';
                            },
                            colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],

                        }
                    },
                    {
                        title: {
                            formatter(val) {
                                return val;
                            },
                            colors: ['#00358e', '#656f88', '#fae100', '#1b2e5b'],
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1'
            }
        };

        this.chartOptionsQuaternary = {
            series: [],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true
            },
            chart: {
                type: 'bar',
                height: 320,
                id: 'quaternaryChart'
            },
            xAxis: {
                categories: this.categoriesCustomQuaternary,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1,
                colors: ['#1b2e5b', '#fae100']
            },
            tooltip: {
                y: {
                    formatter(val) {
                        return '' + val + ' ';
                    }
                }
            }
        };

        this.chartOptionsQuinary = {
            series: [],
            dataLabels: {
                enabled: false
            },
            legend: {
                show: true
            },
            chart: {
                type: 'bar',
                height: 350,
                id: 'quinaryChart'
            },
            xAxis: {
                categories: this.categoriesCustomQuinary,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%'
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1,
                colors: ['#fae100']
            },
            tooltip: {
                y: {
                    formatter(val) {
                        return '' + val + ' ';
                    }
                }
            }
        };
        /*this.chartOptions.labels = [];
        this.chartOptions.series = [];
        this.chartOptionsTertiary.series = [];
        this.chartOptionsQuinary.series = [];
        this.chartOptionsQuaternary.series = [];
        this.categoriesCustomQuaternary = [];*/
    }

    makeData() {
        const labels = [];
        const series = [];
        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.active) {
            labels.push(this.modelDashboard.active[array].stepValue === 5 ?
                this.translate.currentLang === 'pt' ? 'Cadastro' : 'Catastro' : this.modelDashboard.active[array].step);
            series.push(this.modelDashboard.active[array].value);
        }
        this.chartOptions.labels = labels;
        this.chartOptions.series = series;
    }

    makeDataTertiary() {
        let arr = [];
        const open = [];
        const finish = [];

        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.opened) {
            open.push(this.modelDashboard.opened[array].open);
            finish.push(this.modelDashboard.opened[array].finish);
            this.categoriesCustom.push(this.modelDashboard.opened[array].step);
        }
        arr = [
            {
                name: this.translate.instant('finalized'),
                data: finish,
                color: '#1b2e5b'
            },
            {
                name: this.translate.instant('opened'),
                data: open,
                color: '#fae100'
            },
        ];
        this.chartOptionsTertiary.series = arr;
    }

    makeDataSecondary() {
        const arr = [];
        const arr2 = [
            {
                name: 'AR Local',
                data: [45, 52, 38, 24, 33],
                color: '#1b2e5b',
            },
            {
                name: 'AR Internacional',
                data: [35, 41, 62, 42, 13],
                color: '#fae100',
            },
            {
                name: 'Artes',
                data: [87, 57, 74, 99, 75],
                color: '#656f88',
            },
            {
                name: this.translate.instant('register'),
                data: [20, 12, 23, 55, 17],
                color: '#00358e'
            }];

        const colorCustom = [
            '#1b2e5b',
            '#fae100',
            '#656f88',
            '#00358e',
        ];

        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.months.values) {
            let arrValues = [];

            // tslint:disable-next-line:forin
            for (const arrayValues in this.modelDashboard.months.values) {
                arrValues = Object.assign([], this.modelDashboard.months.values[array].count);
            }

            arr.push({
                name: this.modelDashboard.months.values[array].step,
                data: arrValues,
                color: colorCustom[array],
            });
        }
        return arr;
    }

    makeDataQuaternary() {
        let arr = [];
        const open = [];
        const finish = [];

        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.active) {
            // open.push(this.modelDashboard.opened[array].open);
            finish.push(this.modelDashboard.active[array].value);
            this.categoriesCustomQuaternary.push(this.modelDashboard.active[array].step);
        }
        arr = [
            {
                name: this.translate.instant('opened'),
                data: finish,
                color: '#1b2e5b'
            }
        ];
        this.chartOptionsQuaternary.series = arr;
    }

    makeDataQuinary() {
        let arr = [];
        const open = [];
        const finish = [];

        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.avarageTimeDays) {
            // open.push(this.modelDashboard.opened[array].open);
            finish.push(this.modelDashboard.avarageTimeDays[array].days);
            this.categoriesCustomQuinary.push(this.modelDashboard.avarageTimeDays[array].step);
        }
        arr = [
            {
                name: this.translate.instant('days'),
                data: finish,
                color: '#fae100'
            }
        ];
        this.chartOptionsQuinary.series = arr;
    }

    makeDataSecondaryCategories() {
        const arr = this.modelDashboard.months.month;
        return arr;
    }

    makeDataSecondaryDataArrays() {
        const arr = [];
        // tslint:disable-next-line:forin
        for (const array in this.modelDashboard.months.month) {
            arr.push(0);
        }
        return arr;
    }

    actionUpdateGraph() {
        this.flagResultMoc = !this.flagResultMoc;
        this.updateQuarterChart('primaryChart', this.makeData());
        this.updateQuarterChart('secondaryChart', this.makeDataSecondary());
        this.updateQuarterChart('tertiaryChart', this.makeDataTertiary());
    }

    updateQuarterChart(destChartIDToUpdate, func) {
        return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
            series: func
        });
    }

    updateQuarterChartCategories(destChartIDToUpdate, func) {
        return window.ApexCharts.exec(destChartIDToUpdate, 'updateOptions', {
            series: this.makeDataSecondary(),
            xaxis: {categories: this.makeDataSecondaryCategories()}
        });
    }

    onSetFlowType(obj: any) {
        this.currentFlowType = obj;
        this.resetDataCharts();
        this.actionDashBoard();
        if (this.currentFlowType?.value === 'new-business') {
            this.stepsArray = [, 1, 2, 3, 4, 5, 6];
        } else {
            this.stepsArray = [, 1, 2, 3, 4, 5];
        }
    }

    onDetailRequester(id?: string) {
        let str = '';
        console.log('onDetailRequester');
        console.log(this.currentFlowType);
        console.log(id);
        switch (this.currentFlowType?.value) {

            case 'finished-products-partners':
                str = 'register-finished-products-to-partners';
                break;
            case 'bulk':
                str = 'register-bulk';
                break;
            case 'finished-products':
                str = 'register-finished-products';
                break;
            case 'new-products-br':
                str = 'register-new-products-br';
                break;

            case 'genfar':
                str = 'register-genfar';
                break;
            case 'new-business':
                str = 'register-new-business';
                break;
            case 'pmoint':
                str = 'register-country-manufacture';
                break;
            default:
                str = 'register';
                break;
        }
        this.router.navigate([`/${str}/${id}`]);
    }
}




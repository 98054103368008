import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {User} from '../model/user/user';

@Injectable({
    providedIn: 'root'
})
export class RequestService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    search(filter, currentPage) {
        const url = `${this.BASE_URL}/request/search/${currentPage}`;
        return this.http.post(url, filter);
    }

    step(step, model) {
        const url = `${this.BASE_URL}/request/step-${step}`;
        return this.http.post(url, model);
    }

    stepNewProductsBr(step, model) {
        const url = `${this.BASE_URL}/request-new-products-br/step-${step}`;
        return this.http.post(url, model);
    }

    stepFinishedProducts(step, model) {
        const url = `${this.BASE_URL}/request-finished-products/step-${step}`;
        return this.http.post(url, model);
    }

    stepFinishedProductsToPartner(step, model) {
        const url = `${this.BASE_URL}/request-finished-products-to-partners/step-${step}`;
        return this.http.post(url, model);
    }

    stepBulk(step, model) {
        const url = `${this.BASE_URL}/request-bulk/step-${step}`;
        return this.http.post(url, model);
    }

    stepBusiness(step, model) {
        const url = `${this.BASE_URL}/request-new-business/step-${step}`;
        return this.http.post(url, model);
    }

    stepGenfar(step, model) {
        const url = `${this.BASE_URL}/request-genfar/step-${step}`;
        return this.http.post(url, model);
    }

    resetStep(id, model) {
        const url = `${this.BASE_URL}/request/${id}/reset-step`;
        return this.http.post(url, model);
    }

    changeStatus(id, model) {
        const url = `${this.BASE_URL}/request/${id}/change-status`;
        return this.http.put(url, model);
    }

    getId(id) {
        const url = `${this.BASE_URL}/request/${id}`;
        return this.http.get(url);
    }

    getIdBusiness(id) {
        const url = `${this.BASE_URL}/request-new-business/${id}`;
        return this.http.get(url);
    }

    getIdNewProductsBr(id) {
        const url = `${this.BASE_URL}/request-new-products-br/${id}`;
        return this.http.get(url);
    }

    getIdFinisedProducts(id) {
        const url = `${this.BASE_URL}/request-finished-products/${id}`;
        return this.http.get(url);
    }

    getIdFinisedProductsToPartners(id) {
        const url = `${this.BASE_URL}/request-finished-products-to-partners/${id}`;
        return this.http.get(url);
    }

    getIdBulk(id) {
        const url = `${this.BASE_URL}/request-bulk/${id}`;
        return this.http.get(url);
    }

    like(id) {
        const url = `${this.BASE_URL}/request/like/${id}`;
        return this.http.get(url);
    }

    getExcelUser(model) {
        const url = `${this.BASE_URL}/request/list-excel`;
        return this.http.post(url, model);
    }

    getContinent() {
        const url = `${this.BASE_URL}/continent`;
        return this.http.get(url);
    }

    getAllCountries() {
        const url = `${this.BASE_URL}/continent/list-country`;
        return this.http.get(url);
    }

    getCountriesByContinents(model) {
        const url = `${this.BASE_URL}/continent/countries-by-continents`;
        return this.http.post(url, model);
    }

}

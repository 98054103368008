import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterFinishedProductsPartnersComponent} from "./register-finished-products-partners.component";

const routes: Routes = [
  {path: '', component: RegisterFinishedProductsPartnersComponent},
  {path: ':id', component: RegisterFinishedProductsPartnersComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegisterFinishedProductsPartnersRoutingModule { }
